import React from 'react';
import style from "../assets/scss/pages/service.module.scss";
import { services } from '../fetures/config';
import HeroContact from '../componnet/heroContact';
import { useNavigate } from 'react-router-dom';

// Data for services and features
// const services = [
//   {
//     title: 'AI and Machine Learning',
//     description: `Unlock the power of intelligent automation and predictive analytics with our AI and machine learning solutions. From streamlining operations to optimizing decision-making, we help businesses innovate and grow.`,
//     features: [
//       'Generative AI',
//       'Conversational AI',
//       'Data Science',
//       'Machine Learning',
//       'MLOps',
//     ],
//     image: '/path-to-ai-ml-image.png',
//   },
//   {
//     title: 'Cloud Engineering',
//     description: `Leverage the power of the cloud with our secure, scalable, and cost-efficient cloud engineering solutions. We design cloud architecture and provide cloud migration, serverless computing, and security services.`,
//     features: [
//       'Cloud Migration',
//       'Cloud Architecture Design',
//       'Serverless Computing',
//       'Cloud Security & Compliance',
//     ],
//     image: '/path-to-cloud-image.png',
//   },
// ];

export default function Service() {
  const navigate =useNavigate();

  const serviceNavigate = (e, service) => {
    e.preventDefault();
    const url = `/our-services/${service.label.toLowerCase().replace(/\s+/g, '-')}`;
    navigate(url);
  };

  const contact = () => {
    navigate("/contact-us")
   }
  return (
    <div className={style.servicePage}>
      {/* Service Header */}
      <section className={style.headerSection}>
        <div className="container">
          <div className={style.headerContent} >
            <h1>Our Services</h1>
            <p>
              We provide expert software engineering and consultancy services to
              global enterprises, SMEs, and technology challengers.
            </p>
            <button className={style.headerButton} onClick={contact} >Get Expert Advice</button>
          </div>
        </div>
      </section>

      {/* Service Sections */}
      {services.map((service, index) => (
        <section key={index} className={style.serviceSection}>
          <div className="container">
            <div className={style.sectionContent} data-aos="fade-up">
              <div className={style.textContent}>
                <h2>{service.label}</h2>
                <p>{service.description3}</p>

                {/* Features List with Image Icons */}
                <ul className={style.featuresList}>
                  {service.useCases.map((feature, i) => (
                    <li key={i}>
                      <img
                        src="/assets/icon_check.webp"
                        alt="icon"
                        className={style.icon}
                      />
                      {feature.title}
                    </li>
                  ))}
                </ul>
                <button className={style.moreInfoButton} onClick={(e) => serviceNavigate(e, service)}>More Info</button>
              </div>
              <div className={style.imageContent} data-aos="zoom-in">
                <img src={service.img} alt={service.title} />
              </div>
            </div>
          </div>
        </section>
      ))}

      <HeroContact/>
    </div>
  );
}
