import React, { useState, useEffect } from 'react';
import style from '../assets/scss/componnet/nav.module.scss';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { navmenu, services } from '../fetures/config';
import { FaPhoneAlt, FaChevronDown } from 'react-icons/fa';  // Import dropdown icon

export default function Nav() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const handleMouseEnter = () => setIsDropdownOpen(true);
  const handleMouseLeave = () => setIsDropdownOpen(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const contact = () => {
    navigate("contact-us")
  }


  const [showMenu, setShowMenu] = useState(false);
  const [colorChange, setColorChange] = useState(false);
  const [isNavFixed, setIsNavFixed] = useState(false);



  const openModel = () => {
    console.log("Click button");
  };

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -250;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  useEffect(() => {
    const changeNavbarColor = () => {
      if (window.scrollY >= 15) {
        setColorChange(true);
      } else {
        setColorChange(false);
      }
    };

    window.addEventListener("scroll", changeNavbarColor);

    return () => {
      window.removeEventListener("scroll", changeNavbarColor);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const navbarHead = document.querySelector(`.${style.navbar_head}`);

      if (navbarHead) {
        const headerHeight = navbarHead.offsetHeight;

        if (scrollPosition > headerHeight) {
          setIsNavFixed(true);
        } else {
          setIsNavFixed(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const myFunction = (e) => {
    setShowMenu(!showMenu);
    document.body.classList.toggle("no-scroll");
  };



  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  return (
    <>
      <header className={`${style.nav} ${isNavFixed ? style.fixed : ""}`}>
        {/* <div className={style.topnav}>
      <p>Contacct on watsapp 6294467292</p>
      </div> */}
        <div className="container">

          <nav className={style.navbar}>
            <div className={style.navbar__logo}>
              <img src="/assets/logo.webp" alt="" />
            </div>

            <div className={style.navbar__menu}>
              <ul className={style.navbar__menu__links}>
                {navmenu.map((menu) => (
                  <li
                    key={menu.id}
                    onMouseEnter={menu.lable === 'Services' ? handleMouseEnter : null}
                    onMouseLeave={menu.lable === 'Services' ? handleMouseLeave : null}
                  >
                    <NavLink to={menu.url} className={
                      location.pathname + location.hash === menu.url
                        ? style.active
                        : ""
                    }>
                      {menu.lable}
                      {menu.lable === 'Services' && <FaChevronDown className={style.dropdownIcon} />}  {/* Add icon */}
                    </NavLink>
                    {menu.lable === 'Services' && isDropdownOpen && (
                      <div className={style.dropdown}>
                        {services.map((service) => (
                          <div key={service.id} className={style.dropdown__box}>
                            <NavLink to={`/our-services/${service.label.toLowerCase().replace(/\s+/g, '-')}`}>
                              <div className={style.dropdown__box__item}>
                                <h4 className={style.dropdown__box__item__icon}>{service.icon}</h4>
                                <h6 className={style.dropdown__box__item__label}>{service.label}</h6>
                                <span className={style.dropdown__box__item__description}>{service.description}</span>
                              </div>
                            </NavLink>
                          </div>
                        ))}
                      </div>
                    )}
                  </li>
                ))}
              </ul>

              <div className={style.navbar__menu__contact}>
                <FaPhoneAlt className={style.navbar__menu__contact__phoneIcon} />
                <div className={style.navbar__menu__contact__number}>
                  <span>Have any question?</span>
                  <a href="tel:+916294467292">+91 8431 909583</a>
                </div>
                <button onClick={contact} className={style.navbar__menu__contact__button}>Get in touch</button>
              </div>
            </div>
          </nav>
        </div>
      </header>

      {/* mobile view */}

      <header
        className={`${style.mobile_nav} ${colorChange ? style.colorChange : ""
          }`}
      >
        <nav>
          <div style={{ background: "linear-gradient(to left, #68ca48 0%, #0082bf 46.77%, #004676 100%)"}}>

          <div className="container">
            <div className={style.top_nav}>
              <div className={style.top_nav__contact}>
                <FaPhoneAlt className={style.top_nav__contact__phoneIcon} />
                <div className={style.top_nav__contact__number}>
                  <span>Have any question?</span>
                  <a href="tel:+916294467292">+91 8431 909583</a>
                </div>
              </div>
                <button onClick={contact} className={style.top_nav__contact__button}>Get in touch</button>
            </div>
          </div>
          </div>
          <div className="container">
            <div className={style.nav_bar}>
              <div className={style.logo}>
                <Link to="/">
                  <div className={style.logo__}>
                    <img src="/assets/logo.webp" alt="logo" />
                  </div>
                </Link>
              </div>

              <div className={style.toggle_button}>
                <button
                  onClick={myFunction}
                  className={showMenu ? style.menuicons : ""}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
              </div>
            </div>
          </div>
        </nav>
      </header>

      <div className={`${style.toggle_menu} ${showMenu ? style.actives : ""}`}>
        <div className={style.menus}>
          <ul className={style.navMenu}>
            {navmenu.map((menu) => (
              <li key={menu.id} className={style.navMenuItem}>
                <NavLink
                  to={menu.url}
                  className={`${style.navLink} ${location.pathname + location.hash === menu.url ? style.active : ''
                    }`}
                  onClick={(e) => {
                    myFunction(e);
                  }}
                >
                  {menu.lable}

                </NavLink>
                {menu.lable === 'Services' && (
                  <div className={style.dropdown}>
                    {services.map((service) => (
                      <div key={service.id} className={style.dropdown__box}>
                        <NavLink
                          to={`/our-services/${service.label
                            .toLowerCase()
                            .replace(/\s+/g, '-')}`}
                          onClick={(e) => {
                            myFunction(e);
                          }}
                        >
                          -  {service.label}

                        </NavLink>
                      </div>
                    ))}
                  </div>
                )}
              </li>
            ))}
            <li><NavLink onClick={(e) => {
              myFunction(e);
            }} to="contact-us">Contact us</NavLink></li>
          </ul>
        </div>
      </div>
    </>
  );
}


