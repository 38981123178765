import {
  FaRobot, FaCloud, FaCog, FaShieldAlt, FaDatabase
  , FaCloudUploadAlt, FaNetworkWired, FaServer, FaChartLine,
  FaChartPie,
  FaMobileAlt,
  FaCode,
} from 'react-icons/fa';
import { GiArtificialHive } from 'react-icons/gi'; // Example of other icons from different sets

export const navmenu = [{
  id: 1,
  url: "/",
  lable: "Home"
},
{
  id: 2,
  url: "/about-us",
  lable: "About"
},
{
  id: 3,
  url: "/our-services",
  lable: "Services"
},
];



// Import icons from react-icons

export const services = [
  {
    id: 1,
    label: "AI & Machine Learning",
    icon: <FaRobot />,
    img: "/assets/ai.webp",
    description: "Empowering decisions with innovative AI and machine learning solutions",
    description2: "Artificial Intelligence (AI) and Machine Learning (ML) represent a groundbreaking leap in technology, offering unprecedented capabilities across various industries. Whether you're looking to enhance operational processes, streamline decision-making, or drive data-driven innovation, AI and ML are the key to unlocking new possibilities.",
    description3: "Unlock the power of intelligent automation and predictive analytics with our AI and machine learning solutions. From streamlining operations to providing deeper insights, we help businesses innovate and optimize, enabling smarter decision-making and improved efficiency across every facet of your operations. Our tailored AI models are designed to scale with your business needs, ensuring long-term adaptability. Experience faster time-to-market and gain a competitive edge with cutting-edge AI technologies.",
    why: "Why AI and Machine Learning?",
    usecaseHeadDescription: "AI and Machine Learning (ML) are transforming industries by providing innovative solutions to complex problems. Here are some real-world use cases that demonstrate the power and potential of these technologies across various sectors",
    benefits: [
      "Automate Complex Tasks: Reduce manual labor and human error by automating routine and complex operations.",
      "Make Data-Driven Decisions: Utilize AI to analyze vast amounts of data in real-time, providing actionable insights that drive better decision-making.",
      "Enhance Customer Experiences: Create personalized interactions by understanding and predicting customer needs through ML-powered analytics.",
      "Boost Operational Efficiency: Optimize workflows and processes by integrating AI into your core systems, increasing productivity, and reducing costs.",
      "Drive Innovation: Discover new business models and innovative solutions powered by AI’s ability to generate new ideas and approaches."
    ],
    useCases: [
      {
        title: "Generative AI",
        description: "AI systems that can create new content, such as images, text, and music.",
        image: "/assets/one/Ai/1.webp" // Example: Image for generative AI
      },
      {
        title: "MLOps",
        description: "Streamline the process of machine learning model development, deployment, and monitoring.",
        image: "/assets/one/Ai/2.webp" // Example: Image for MLOps
      },
      {
        title: "Conversational AI",
        description: "Implement chatbots and voice assistants to enhance customer interaction and support.",
        image: "/assets/one/Ai/3.webp" // Example: Image for conversational AI
      },
      {
        title: "Data Science",
        description: "Analyze large datasets to uncover trends and insights, helping businesses make data-driven decisions.",
        image: "/assets/one/Ai/4.webp" // Example: Image for data science
      }
    ]

  },
  {
    id: 2,
    label: "Cloud Engineering",
    icon: <FaCloud />,
    img: "/assets/cloud.webp",
    description: "Scalable cloud solutions enabling secure, agile, and efficient business operations.",
    description2: "Cloud Engineering represents a transformative shift in technology, delivering scalable, flexible, and cost-efficient infrastructure across industries. Whether optimizing IT environments, accelerating development, or enabling global data access, Cloud Engineering unlocks new opportunities. This approach empowers businesses to adapt quickly, innovate continuously, and scale effortlessly in today’s digital landscape.",
    description3: "Leverage the power of the cloud with our secure, scalable, and cost-efficient cloud engineering solutions. We design and deploy custom cloud architectures that boost performance, improve flexibility, and streamline operations. Whether migrating to the cloud or optimizing your existing infrastructure, we ensure seamless integration and minimal downtime. Experience faster innovation and agility with cloud technologies tailored to your business needs.",
    why: "What Are the Key Benefits of Cloud Engineering?",
    usecaseHeadDescription: "Cloud engineering is reshaping how organizations operate by providing scalable, flexible, and efficient solutions. Here are some prominent use cases that illustrate its impact across various sectors:",
    benefits: [
      "Scalability: Easily scale resources up or down based on demand, ensuring optimal performance during peak times without overspending.",
      "Flexibility: Adapt to changing business needs with cloud solutions that allow for quick deployment and integration of new services.",
      "Cost Efficiency: Reduce capital expenses by transitioning to a pay-as-you-go model, which eliminates the need for extensive on-premises infrastructure.",
      "Enhanced Collaboration: Foster collaboration across teams with cloud-based tools that enable real-time communication and information sharing.",
      "Improved Reliability: Benefit from high availability and disaster recovery solutions that ensure business continuity and data protection."
    ],
    useCases: [
      {
        title: "Cloud Migration",
        description: "Cloud migration enables businesses to transition their applications and data to the cloud, enhancing scalability and reducing IT costs.",
        image: "/assets/one/cloud/1.webp"
      },
      {
        title: "Multi-Cloud Strategy",
        description: "A multi-cloud strategy allows organizations to leverage services from multiple cloud providers, enhancing flexibility and reducing vendor lock-in.",
        image: "/assets/one/cloud/2.webp"
      },
      {
        title: "Serverless Computing",
        description: "Serverless computing allows developers to build and run applications without managing infrastructure, automatically scaling resources based on demand.",
        image: "/assets/one/cloud/3.webp"
      },

      {
        title: "Cloud Security & Compliance",
        description: "Cloud security and compliance solutions enable organizations to protect sensitive data and ensure adherence to regulatory requirements.",
        image: "/assets/one/cloud/4.webp"
      },
      {
        title: "Cloud Optimization",
        description: "Cloud optimization involves analyzing and fine-tuning cloud resources to enhance performance and reduce costs.",
        image: "/assets/one/cloud/5.webp"
      }
    ]
  },
  {
    id: 3,
    label: "Data Engineering",
    img: "/assets/data.webp",
    icon: <FaDatabase />,
    description: "Seamless data integration and architecture for improved decision-making and efficiency",
    description2: "In today's data-driven world, effective data engineering is crucial for businesses seeking to harness the power of their data. At One08.Tech, we specialize in building robust data pipelines, optimizing data architecture, and delivering actionable insights that drive informed decision-making. Our expert team works closely with clients to design and implement tailored solutions that meet their unique data needs.",
    description3: "Harness the power of your data with our expert data engineering solutions. We design and build scalable data architectures, optimize data pipelines, and implement ETL processes to ensure seamless data flow and accessibility. Our focus on data quality and governance enables organizations to leverage their data for actionable insights and informed decision-making. Transform your data into a strategic asset today.",

    why: "Modernize Your Data Infrastructure and Unlock New Insights",
    usecaseHeadDescription: "Data Engineering represents a transformative shift in how organizations manage and utilize their data, providing unparalleled opportunities for innovation and growth. Whether you're looking to optimize data pipelines, enhance analytics capabilities, or enable real-time decision-making, effective data engineering is essential for unlocking valuable insights and driving business success.",
    benefits: [
      "Seamless Integration: Implement advanced data integration solutions to consolidate disparate data sources, enabling a unified view that enhances analysis and reporting.",
      "Scalable Architecture: Design a flexible and scalable data architecture that can grow with your business, accommodating increasing data volumes and evolving analytical needs.",
      "Real-Time Analytics: Leverage real-time data processing capabilities to gain timely insights and make informed decisions quickly, responding proactively to market changes and customer demands.",
      "Data Quality Assurance: Establish robust data quality management practices to ensure accuracy, completeness, and reliability, leading to more trustworthy insights and better decision-making",
      "Enhanced Data Governance: Implement strong data governance frameworks to ensure compliance with regulations, maintain data integrity, and promote responsible data usage across the organization."
    ],
    useCases: [
      {
        title: "Data Warehousing",
        description: "Data warehousing enables organizations to consolidate and store large volumes of structured and unstructured data in a centralized repository, facilitating efficient querying and reporting. By providing a unified view of data, businesses can gain actionable insights and make informed decisions based on comprehensive historical analysis.",
        image: "/assets/one/data/1.webp"// Icon representing the cloud
      },

      {
        title: "Big Data Engineering",
        description: "Big data engineering involves designing and implementing scalable architectures to process and analyze vast volumes of data from diverse sources. By leveraging distributed computing frameworks, organizations can derive valuable insights, enhance decision-making, and identify trends that drive innovation and growth.",
        image: "/assets/one/data/2.webp"// Icon for serverless/data management
      },
      {
        title: "Data Lakes Management",
        description: "Data lakes management enables organizations to store and process large volumes of structured and unstructured data in a cost-effective manner. By providing a flexible and scalable architecture, businesses can easily ingest, catalog, and analyze diverse datasets, unlocking valuable insights for informed decision-making.",
        image: "/assets/one/data/3.webp"// Icon representing security
      },
      {
        title: "Data Pipeline Development",
        description: "Data pipeline development streamlines the process of extracting, transforming, and loading data from multiple sources into a centralized database or data warehouse. This automated workflow ensures timely access to high-quality data, enabling organizations to derive actionable insights and support real-time analytics.",
        image: "/assets/one/data/4.webp"// Icon representing optimization/engineering
      }
    ]
  },

  {
    id: 4,
    label: "Analytics & Visualization",
    icon: <FaChartPie />,
    img: "/assets/annlys.webp",
    description: "Transforming data into insights with powerful analytics and visual storytelling.",
    description2: "Analytics and visualization represent a transformative leap in understanding and leveraging data, offering unparalleled capabilities across various industries. Whether you’re looking to uncover hidden insights, enhance decision-making processes, or communicate data effectively, analytics and visualization are the keys to unlocking new possibilities.",
    description3: "Unlock valuable insights with our comprehensive analytics and visualization solutions. We transform complex data into intuitive dashboards and reports, enabling data-driven decision-making. Our services include predictive analytics, business intelligence, and custom visualizations that empower your team to understand trends and performance metrics. Enhance your strategic initiatives with actionable insights tailored to your business needs.",
    why: "Industry Insights and Expert Opinions",
    usecaseHeadDescription: "Analytics and visualization are crucial for businesses seeking to harness the power of their data. Whether you’re looking to streamline operations or enhance customer experiences, these tools are essential for unlocking new possibilities.",
    benefits: [
      "Data analytics is not just a technological advancement; it’s a strategic tool that will reshape how organizations interpret and act on data.",
      "Effective data visualization is becoming essential for businesses, providing clear, actionable insights that drive informed decisions and strategy.",
      "Harnessing the power of analytics enables organizations to identify trends, forecast future scenarios, and optimize operational efficiency in ways we have yet to fully explore.",

    ],
    useCases: [
      {
        title: "Data Analysis",
        description: "Data analysis enables organizations to interpret complex datasets, revealing patterns and insights that drive strategic decision-making. By leveraging analytical techniques, businesses can identify trends, optimize operations, and enhance customer experiences, ultimately leading to improved performance and growth.",
        image: "/assets/one/analysis/analysis (1).webp" // Icon representing the cloud
      },
      {
        title: "Data Visualization",
        description: "Data visualization transforms complex data into easily digestible visual formats, allowing stakeholders to quickly grasp insights and trends. By using interactive charts and graphs, organizations can enhance communication, drive data-driven decisions, and foster a deeper understanding of their performance metrics.",
        image: "/assets/one/analysis/analysis (2).webp"// Icon for strategy/operations
      },
      {
        title: "Reporting Automation",
        description: "Reporting automation streamlines the process of generating and distributing reports, reducing manual effort and minimizing errors. By automating data collection and presentation, organizations can deliver timely insights to stakeholders, enabling faster decision-making and improving overall operational efficiency.",
        image: "/assets/one/analysis/analysis (3).webp"// Icon for serverless/data management
      },
      {
        title: "Custom Dashboard Development",
        description: "Custom dashboard development enables organizations to create tailored visual interfaces that present key performance indicators (KPIs) and metrics relevant to their specific needs. By integrating data from various sources, these dashboards provide real-time insights and facilitate informed decision-making, enhancing operational efficiency and strategic planning.",
        image: "/assets/one/analysis/analysis (5).webp" // Icon representing security
      },
      {
        title: "Business Intelligence (BI) Solutions",
        description: "Business Intelligence (BI) solutions empower organizations to transform raw data into actionable insights through comprehensive analysis and reporting tools. By leveraging BI technologies, businesses can visualize data trends, identify growth opportunities, and enhance decision-making processes, ultimately driving improved performance and competitive advantage.",
        image: "/assets/one/analysis/analysis (6).webp" // Icon representing optimization/engineering
      }
    ]
  },

  {
    id: 5,
    label: "Website Development",
    icon: <FaCode />,
    description: "Creating user-friendly websites that engage, inspire, and drive results.",
    description2: "In today’s digital landscape, having a robust online presence is essential for success. Our website development services provide you with customized solutions that not only enhance user experience but also drive engagement and conversions. Whether you need a simple informational site or a complex e-commerce platform, we are dedicated to bringing your vision to life.",
    description3: "We create custom websites that elevate your brand and drive results. Our team specializes in responsive design, ensuring your site delivers an exceptional user experience across all devices. From e-commerce platforms to content management systems, we tailor solutions to meet your specific business needs. With a focus on SEO and UI/UX design, we optimize your site for visibility and engagement. Let us help you build a powerful online presence that attracts and retains customers",
    img: "/assets/web.webp",
    why: "Why Website Development Matters ?",
    usecaseHeadDescription: "Our website development services cater to diverse business needs, providing tailored solutions that align with your specific goals and objectives. From enhancing user engagement to driving conversions, explore some of the key use cases below that highlight our expertise and commitment to delivering exceptional digital experiences.",
    benefits: [
      `Enhance User Experience: Create intuitive and user-friendly interfaces that keep visitors engaged and encourage interaction.`,
      "Mobile Responsiveness: Ensure your website is accessible on all devices, adapting seamlessly to various screen sizes for a consistent experience.",
      "Brand Visibility: Establish a strong digital footprint that enhances your brand’s visibility and credibility in the market.",
    ],
    useCases: [
      {
        title: "UI/UX Design",
        description: "UI/UX design focuses on creating intuitive and engaging interfaces that enhance user satisfaction and drive interaction. By prioritizing user experience through thoughtful design and usability testing, businesses can boost customer retention and foster brand loyalty.",
        image: "/assets/one/websit/web (1).webp" // Icon representing the cloud
      },
      {
        title: "Website Maintenance and Support",
        description: "Website maintenance and support ensure your online presence remains functional, secure, and up-to-date. By regularly updating content, monitoring performance, and addressing technical issues, businesses can enhance user experience, protect against vulnerabilities, and ensure optimal site performance, ultimately leading to increased customer satisfaction and engagement.",
        image: "/assets/one/websit/web (4).webp" // Icon for strategy/operations
      },
      {
        title: "Responsive Design",
        description: "Responsive design ensures that websites provide an optimal viewing experience across a variety of devices and screen sizes, from desktops to smartphones. By implementing flexible layouts, images, and CSS media queries, businesses can enhance user engagement and accessibility, ensuring that their content is easily navigable and visually appealing regardless of the device used.",
        image: "/assets/one/websit/web (2).webp"// Icon for serverless/data management
      },
      {
        title: "API Integration",
        description: "API integration enables seamless communication between different software applications, allowing them to share data and functionalities in real time. By connecting various systems through APIs, businesses can streamline operations, enhance user experiences, and leverage third-party services, ultimately driving efficiency and innovation in their digital strategies.",
        image: "/assets/one/websit/web (3).webp" // Icon representing security
      },
    ]
  },
  {
    id: 6,
    label: "App Development",
    icon: <FaMobileAlt />,
    img: "/assets/app.webp",
    description: "Building powerful, user-centric apps that elevate your business and brand.",
    description2: "In an increasingly mobile world, having a robust application can significantly enhance your business's reach and engagement. Our app development services focus on creating custom mobile applications that cater to your unique business needs, whether for iOS, Android, or cross-platform solutions. We leverage the latest technologies and frameworks to deliver high-quality, user-friendly apps that elevate your brand and connect you with your audience.",
    description3: "We specialize in custom app development for iOS and Android platforms. Our team designs intuitive, user-friendly applications that enhance engagement and drive results. From concept to deployment, we ensure a seamless development process, incorporating features like API integration and ongoing support. Transform your ideas into powerful mobile solutions that meet your business goals",
    why: "Why App Development Matters ?",
    usecaseHeadDescription: "Our app development services address a wide range of business needs, offering tailored solutions designed to meet the unique challenges faced by various industries. Explore some of the key use cases below that highlight our expertise and demonstrate how our applications can drive innovation and enhance user engagement for your business.",
    benefits: [
      "Enhanced User Engagement: Mobile apps provide a direct channel for engagement, allowing businesses to connect with customers through personalized experiences and timely notifications.",
      "Increased Accessibility: With mobile applications, your services are available at users’ fingertips, enhancing convenience and accessibility for your customers.",
      "Brand Loyalty: A well-designed app can foster brand loyalty, encouraging users to return for repeat business and increasing customer retention rates.",

    ],
    useCases: [
      {
        title: "Android App Development",
        description: "Android app development enables businesses to reach a vast user base by creating tailored applications that run on the Android operating system. By leveraging the platform's extensive features and capabilities, organizations can enhance user engagement, drive customer loyalty, and offer seamless experiences across a wide range of devices.",
        image: "/assets/one/app/app (1).webp"// Icon representing the cloud
      },
      {
        title: "OS App Development",
        description: "Operating system (OS) app development focuses on creating applications specifically designed to run on a particular OS, such as iOS or Windows. By optimizing performance and leveraging unique platform features, businesses can deliver tailored user experiences that enhance functionality, engagement, and overall satisfaction for their target audience.",
        image: "/assets/one/app/app (2).webp"// Icon for strategy/operations
      },
      {
        title: "App UI/UX Design",
        description: "App UI/UX design emphasizes creating visually appealing and user-friendly interfaces that enhance the overall user experience. By focusing on intuitive navigation, responsive layouts, and engaging visual elements, businesses can ensure that their applications are not only functional but also enjoyable to use, ultimately driving higher user satisfaction and retention rates.",
        image: "/assets/one/app/app (3).webp"// Icon for serverless/data management
      },
      {
        title: "App Maintenance and Support",
        description: "We offer regular updates, bug fixes, and performance monitoring to keep your app up-to-date and error-free. Our team provides 24/7 technical support to address issues promptly and minimize downtime. Whether it’s scaling for new users or enhancing features, we help your app evolve with your business. Trust us to deliver seamless user experiences and long-term app stability.",
        image: "/assets/one/app/app (4).webp"// Icon representing security
      },

    ]
  },
  // Add other services with their respective content and icons
];
