import React, { useState } from "react";
import Herocontact from "./../componnet/heroContact";
import Banner from "../componnet/banner";
import style from "./../assets/scss/pages/about.module.scss";
import { services } from "../fetures/config";
import HeroContact from "./../componnet/heroContact";
import { useNavigate } from "react-router-dom";

export default function About() {
  const [activeSection, setActiveSection] = useState("Mission");
  const navigate = useNavigate();
  const content = {
    Mission:
      "At one08.tech, we are dedicated to empowering businesses through innovative technology solutions that drive digital transformation and operational efficiency. Founded with a vision to harness the full potential of AI, cloud computing, and data-driven technologies, we are committed to delivering solutions that shape the future of business.",
    Vision:
      "vision At one08.tech, we are dedicated to empowering businesses through innovative technology solutions that drive digital transformation and operational efficiency. Founded with a vision to harness the full potential of AI, cloud computing, and data-driven technologies, we are committed to delivering solutions that shape the future of business.",
  };


  const serviceNavigate = (e, service) => {
    e.preventDefault();
    const url = `/our-services/${service.label.toLowerCase().replace(/\s+/g, '-')}`;
    navigate(url);
  };

  return (
    <div>
      <Banner />
      <div className="container">


        <div className={style.aboutUs1__info}>
          <div className={style.aboutUs1__info__content}>
            <h2>Why Choose Us?</h2>
            <p>
              With a team of seasoned experts, we bring together deep technical
              knowledge and a passion for solving complex problems. Our
              client-first approach ensures that every solution is tailored,
              innovative, and results-oriented. Whether you’re a startup looking
              to scale or an enterprise aiming to modernize,108.tech provides the
              expertise and guidance you need to succeed.
            </p>
            <p>{content[activeSection]}</p>
          </div>
          <img
            src="/assets/about2.webp"
            alt="Our Team"
            className={style.aboutUs1__info_img2}
          />
          
        </div>


        <section className={style.services}>
          <div className="container">
            <div className={style.head} data-aos="fade-up">

              <h1>What We Do</h1>
              <p>We provide cutting-edge IT services, including software development, cloud solutions, cybersecurity, and IT consulting, to help businesses thrive in the digital era. Our expertise ensures seamless technology integration and innovation for sustainable growth.</p>
            </div>
            <div className={style.services__grid}>
              {services.map((service) => {
                return (
                  <div className={style.services__grid__serviceItem} onClick={(e) => serviceNavigate(e, service)} data-aos="flip-left">
                    <div className={style.imge}>

                      <img src={service.img} alt="" />
                    </div>
                    <div className={style.content}>
                    <h3>{service.label}</h3>
                    <p>{service.description}</p>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </section>

        <HeroContact />
      </div>

    </div>
  );
}
